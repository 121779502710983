<template>
  <div class="property-updates-wrapper">
    <Permission/>
    <nav class="breadcrumb is-medium mb-6" aria-label="breadcrumbs">
      <ul>
        <li><a @click="toPropertyUpdatesPage" class="previous">Property Updates</a></li>
        <li class="is-active"><a href="#" aria-current="page">New</a></li>
      </ul>
    </nav>
    <Form
      @onSave="createPropertyUpdate"
      :is-saving="isSaving"
      :flash-content="flashContent"
      :hide-flash="hideFlash"/>
  </div>
</template>
<script>
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Form from './Form.vue'
import { createPropertyUpdate } from '@api/addy-plus/property-updates'

export default {
  title: 'Property Updates',
  mixins: [pageTitleMixin],
  components: {
    Permission,
    Form,
  },
  data() {
    return {
      isSaving: false,
      flashContent: '',
    }
  },
  computed: {
    propertyId() {
      return this.$route.params.propertyId
    },
  },
  methods: {
    toPropertyUpdatesPage() {
      this.$router.go(-1)
    },
    createPropertyUpdate(formData) {
      this.isSaving = true
      createPropertyUpdate(this.propertyId, formData).then((res) => {
        if (!res.success) return
        this.toPropertyUpdatesPage()
      })
    },
    hideFlash() {
      this.flashContent = ''
    },
  },
}
</script>
